import React, { useEffect, useState, FC } from 'react';

import styled from '@emotion/styled';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { TaskQuery, TaskDetailsQuery } from '../../../generated/graphql';
import { getInitialDraftValue } from '../../../utils/helper';
import { CreateUpdateTaskForm } from '../../common/CreateUpdateTaskForm/CreateUpdateTaskForm';
import { DraftRichText } from '../../common/DraftRichText/DraftRichText';
import { Attachments } from './Attachments/Attachments';
import { SubtaskList } from './SubtaskList/SubtaskList';
import { TaskOwners } from './TaskOwners/TaskOwners';
import { ActionsMenu } from './ActionsMenu/ActionsMenu';
import { ImageLinks } from './sections/ImageLinks';
import { formatShortDate } from '../../../utils/date';
import LunesField from './fields/Field';
import RoomScanField from './fields/RoomScanField';
import { TaskTypes } from '../../../utils/constants';

const payloadTitles: { [key: string]: string } = {
  createdAt: 'Created At',
  dueDate: 'Due Date',
  kustomerId: 'Kustomer ID',
  productTaxonomy: 'Product Taxonomy',
  havenlyAssetId: 'Havenly Asset ID',
  sourceUrl: 'Source URL',
  similarPropUrl: 'Similar Prop URL',
  requesterId: 'Requester ID',
  dimensions: 'Dimensions',
  floorPlanImages: 'Floor Plan Images',
  roomImages: 'Room Images',
  custom: 'Custom',
  vendorVariantImageUrl: 'Vendor Variant Image URL',
  vendorVariantTitle: 'Vendor Variant Title',
  vendorVariantSKU: 'Vendor SKU',
  vendorVariantWeblink: 'Vendor URL',
  baseAssetHash: 'Base Asset Hash',
  assetUrl: 'Asset URL',
  assetMetadata: 'Asset Metadata',
  renderRequestId: 'Render Request ID',
  roomProfileImages: 'Room Profile Images',
  approvedQARenders: 'Approved QA Renders',
  latestPublishedConceptBoard: 'Latest Published Concept Board',
  categoryId: 'Category ID',
  creatorCategoryId: 'Creator Category ID',
  extraTitle: 'Extra Title',
  thumbnailUrl: 'Thumbnail URL',
  materialTitle: 'Material Title',
  roomShellRequestId: 'Room Shell Request ID',
  roomShellRequestType: 'Room Shell Request Type',
  vendorProjectUrl: 'Vendor Project URL',
  issues: 'Issues',
  revisionImages: 'Revision Images',
  materialRequestId: 'Material Request ID',
  requestReason: 'Request Reason',
  searchedForSimilarProp: 'Has searched for similar prop',
  vendorIsGoldTier: 'Vendor is gold tier or above',
  boardBuilderURLs: 'Board Builder URL'
};

const ignoredFields = ['custom', 'renderDataPayload'];

// Separated by comma
const imageFields = [
  'floorPlanImages',
  'roomImages',
  'roomProfileImages',
  'approvedQARenders',
  'revisionImages',
];

const urlFields = [
  'sourceUrl',
  'similarPropUrl',
  'vendorVariantImageUrl',
  'vendorVariantWeblink',
  'assetUrl',
  'latestPublishedConceptBoard',
];

const Wrapper = styled.div`
  .ql-editor {
    padding-left: 0;
    padding-right: 0;
  }
`;

const isDevOrLocalEnv = ['dev', 'local'].includes(
  process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : '',
);

export interface TaskProps extends TaskQuery {
  editable?: boolean;
  task: TaskDetailsQuery['task'];
}

export const Task: FC<TaskProps> = ({ task, editable }) => {
  const [editTaskMode, setEditTaskMode] = useState(false);
  const [draftState, setDraftState] = useState<any>();

  useEffect(() => {
    setDraftState(getInitialDraftValue(task.content));
  }, [task.content]);

  if (editTaskMode) {
    return <CreateUpdateTaskForm toggleEditMode={() => setEditTaskMode(false)} task={task} />;
  }

  const getBBUrls = (task: any) => {
    const url = "https://dev.havenly.com/board-builder?legacySearch=true&page=1&download-props=true&query=";
    const devUrlTitle = url + encodeURIComponent(task.payload.vendorVariantTitle);
    const devUrlVVID = url + task.title; //task.title is vvid
    const urlProd = url.replace('dev.havenly.com', 'havenly.com');
    const prodUrlTitle = urlProd + encodeURIComponent(task.payload.vendorVariantTitle);
    const prodUrlVVID = urlProd + task.title; //task.title is vvid
    return [devUrlTitle, devUrlVVID, prodUrlTitle, prodUrlVVID]
  };

  // Since the order is arbitrary, sorting the fields like this is easier.
  const taskFields = Object.entries({
    status: task.status?.toString(),
    roomShellRequestId: task.payload.roomShellRequestId,
    createdAt: task.createdAt,
    dueDate: task.dueDate,
    issues: task.payload.issues,
    revisionImages: task.payload.revisionImages,
    floorPlanImages: task.payload.floorPlanImages,
    roomImages: task.payload.roomImages,
    ...task.payload,
    boardBuilderURLs: getBBUrls(task),
  });

  return (
    <Wrapper>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', md: 'center' },
          marginBottom: 3,
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Typography variant="h4" component="h2" sx={{ mr: 2 }}>
          {task.title} #{task.id}
        </Typography>

        <ActionsMenu task={task} />

        <TaskOwners task={task} />
      </Box>

      <DraftRichText readOnly draftState={draftState} setDraftState={setDraftState} />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' },
          gridGap: 15,
          my: 3,
        }}
      >
        <LunesField title="Status" text={task.status?.toString()} />

        {taskFields.map(([key, value]) => {
          if (value === null || value === undefined || value === '') {
            return null;
          }

          if (!(key in payloadTitles)) {
            return null;
          }

          if (ignoredFields.includes(key)) {
            return null;
          }

          const title = key as string;
          const val = getFormattedValue(value);

          if (title === 'createdAt' || title === 'dueDate') {
            return (
              <LunesField key={title} title={payloadTitles[title]} text={formatShortDate(val)} />
            );
          }

          if (title === 'issues') {
            return (
              <LunesField
                key={title}
                title={payloadTitles[title]}
                text={getFormattedIssues(val)}
                mb={2}
              />
            );
          }

          if(title === 'boardBuilderURLs') {
            if (task.taskTypeId === TaskTypes['Prop'] || task.taskTypeId === TaskTypes['Generic Prop']) {
              if (isDevOrLocalEnv) {
                return (
                  <LunesField
                    key={title}
                    title={payloadTitles[title]}
                    text={
                      <div>
                        <Link href={val[0] || '#'} sx={{ wordBreak: 'break-all' }} target="_blank">
                          DEV: Search By Title
                        </Link>
                        <br/>
                        <Link href={val[1] || '#'} sx={{ wordBreak: 'break-all' }} target="_blank">
                          DEV: Search By VVID
                        </Link>
                      </div>
                    }
                    mb={2}
                  />
                );   
              }  
              else {
                return (
                  <LunesField
                    key={title}
                    title={payloadTitles[title]}
                    text={
                      <div>
                        <Link href={val[2] || '#'} sx={{ wordBreak: 'break-all' }} target="_blank">
                          PROD: Search By Title
                        </Link>
                        <br/>
                        <Link href={val[3] || '#'} sx={{ wordBreak: 'break-all' }} target="_blank">
                          PROD: Search By VVID
                        </Link>
                      </div>
                    }
                    mb={2}
                  />
                );   
              }
            } else {
              return (
                <div></div>
              );
            }
          }

          if (imageFields.includes(title)) {
            return (
              <LunesField
                key={title}
                title={payloadTitles[title]}
                text={<ImageLinks imageURLs={val} />}
              />
            );
          }

          if (urlFields.includes(title)) {
            return (
              <LunesField
                key={title}
                title={payloadTitles[title]}
                text={
                  <Link href={val || '#'} sx={{ wordBreak: 'break-all' }} target="_blank">
                    {val}
                  </Link>
                }
              />
            );
          }

          return <LunesField key={title} title={payloadTitles[title]} text={val} />;
        })}

        {/* LiDAR aka Room Scan */}
        { task.taskTypeId === TaskTypes['Room Shell'] && (
          <RoomScanField task={task} />
        )}

        {task && task.attachments && task?.attachments?.length > 0 && (
          <Attachments attachments={task.attachments} sx={{ gridColumn: 'span 2' }} />
        )}
      </Box>

      {editable && (
        <Button
          variant="outlined"
          startIcon={<EditIcon />}
          onClick={() => setEditTaskMode(!editTaskMode)}
        >
          Edit
        </Button>
      )}

      {task && task.subtasks && task.subtasks.length > 0 && (
        <SubtaskList subtasks={task.subtasks} />
      )}
    </Wrapper>
  );
};

/**
 * Removes revisionImages from issues
 * They are already displayed in the Revision Images field
 */
function getFormattedIssues(rawIssues: string) {
  const items = rawIssues.split('||').filter((item) => !item.includes('{"revisionImages"'));

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {items.map((item) => (
        <Typography variant="body2" component="p" sx={{ mb: 2 }}>
          {item}
        </Typography>
      ))}
    </Box>
  );
}

function getFormattedValue(value: any): string {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  return value as string;
}
